/**
 * Render text with typing Effect - no cursor
 * @param {string} content
 * @param {HTMLElement} optional element
 * @param {onRending} optional callback when rending text
 * @param {function} optional onComplete callback when rendering is complete,
 * @param {function} optional onCancel callback when rendering should be cancelled
 * @returns interval ID so component using this function can clearInterval when destroyed to stop rendering
 */
export const renderTextTypingEffect = (content, element, onRending, onComplete, onCancel) => {
	const typingElement =
		element != null ? document.getElementById(element) : document.getElementById('typing-paragraph');
	if (typingElement == null) return;

	const multiLines = content.split('\n');
	let lineIndex = 0;
	let charIndex = 0;
	let intervalID;
	let pTag;
	let isBold = false;

	const htmlEntities = {
		é: '&eacute;',
		à: '&agrave;',
		è: '&egrave;',
		ê: '&ecirc;',
		ô: '&ocirc;',
		ù: '&ugrave;',
		ç: '&ccedil;',
		É: '&Eacute;',
		À: '&Agrave;',
		È: '&Egrave;',
		Ê: '&Ecirc;',
		Ô: '&Ocirc;',
		Ù: '&Ugrave;',
		Ç: '&Ccedil;',
	};

	const escapeHtml = (text) => {
		return text.replace(/[éàèêôùçÉÀÈÊÔÙÇ]/g, (char) => htmlEntities[char] || char);
	};

	const processLine = (line) => {
		const parts = line.split(/(\*\*[^*]+\*\*)/); // Split by bold segments
		const processedParts = parts.map((part) => {
			if (part.startsWith('**') && part.endsWith('**')) {
				return `<strong>${part.slice(2, -2)}</strong>`; // Wrap in <strong>
			}
			return escapeHtml(part);
		});
		return processedParts.join('');
	};

	const getNextChar = (processedLine, charIndex) => {
		const char = processedLine[charIndex];
		if (char === '&') {
			const entityEnd = processedLine.indexOf(';', charIndex) + 1;
			return [processedLine.slice(charIndex, entityEnd), entityEnd - charIndex];
		}
		return [char, 1];
	};

	intervalID = setInterval(() => {
		// Check for cancel callback
		if (typeof onCancel === 'function' && onCancel()) {
			clearInterval(intervalID);
			return;
		}
		const currentLine = multiLines[lineIndex];
		if (currentLine == null) {
			if (onComplete) onComplete();
			clearInterval(intervalID);
		} else {
			const processedLine = processLine(currentLine);
			const [currentChar, charLength] = getNextChar(processedLine, charIndex);
			if (currentLine.length === 0) {
				lineIndex += 1;
			} else {
				if (charIndex === 0) {
					pTag = document.createElement('p');
					pTag.classList.add('explanation-chat');
					typingElement.appendChild(pTag);
				}

				// Insert current character
				if (currentChar === '<' && processedLine.slice(charIndex, charIndex + 8) === '<strong>') {
					isBold = true;
					pTag.innerHTML += '<strong>';
					charIndex += 7;
				} else if (currentChar === '<' && processedLine.slice(charIndex, charIndex + 9) === '</strong>') {
					isBold = false;
					pTag.innerHTML += '</strong>';
					charIndex += 8;
				} else if (currentChar === '<' && processedLine.slice(charIndex, charIndex + 4) === '<br>') {
					pTag.innerHTML += '<br>';
					charIndex += 3;
				} else if (currentChar === '<' && processedLine.slice(charIndex, charIndex + 5) === '</br>') {
					pTag.innerHTML += '</br>';
					charIndex += 4;
				} else if (currentChar === '<' && processedLine.slice(charIndex, charIndex + 3) === '<p>') {
					pTag.innerHTML += '<p>';
					charIndex += 2;
				} else if (currentChar === '<' && processedLine.slice(charIndex, charIndex + 4) === '</p>') {
					pTag.innerHTML += '</p>';
					charIndex += 3;
				} else {
					if (isBold) {
						pTag.lastChild.innerHTML += currentChar;
					} else {
						pTag.innerHTML += currentChar;
					}
					charIndex += charLength - 1;
				}

				if (charIndex + 1 === processedLine.length) {
					lineIndex += 1;
					charIndex = 0;
				} else {
					charIndex += 1;
				}
				if (onRending) {
					onRending();
				}
			}
		}
	}, 20);

	return intervalID;
};

/**
 * Render text without typing Effect - no cursor
 * @param {string} content
 * @param {HTMLElement} optional element
 * @param {function} optional onComplete callback when rendering is complete,
 * @returns void
 */
export const renderTextWithoutTypingEffect = (content, element, onComplete) => {
	const typingElement =
		element != null ? document.getElementById(element) : document.getElementById('typing-paragraph');
	if (typingElement == null) return;

	const multiLines = content.split('\n');

	const processLine = (line) => {
		const parts = line.split(/(\*\*[^*]+\*\*)/); // Split by bold segments
		const processedParts = parts.map((part) => {
			if (part.startsWith('**') && part.endsWith('**')) {
				return `<strong>${part.slice(2, -2)}</strong>`; // Wrap in <strong>
			}
			return part; // Normal text
		});
		return processedParts.join('');
	};

	// Clear existing content
	typingElement.innerHTML = '';

	// Process and append each line
	multiLines.forEach((line) => {
		const pTag = document.createElement('p');
		pTag.classList.add('explanation-chat', 'fade-in');
		pTag.innerHTML = processLine(line);
		typingElement.appendChild(pTag);
	});

	if (onComplete) onComplete();
};

<template>
	<v-dialog
		persistent
		scrollable
		:fullscreen="belowDesktop"
		v-model="dialog"
		transition="dialog-bottom-transition"
		max-width="1000px"
	>
		<v-card>
			<v-card-title>
				<div class="d-flex justify-end w-100">
					<v-btn text icon class="close-btn mt-2" @click="close()">
						<v-icon x-large color="black">mdi-close</v-icon>
					</v-btn>
				</div>
			</v-card-title>
			<v-card-text class="hide-scroll-bar">
				<div class="question-block">
					<div class="questions-answers-label--correct text-uppercase">
						{{ question.answers[question.good_answer] }}
					</div>
					<span class="question-libelle">{{ questionLibelle }}</span>
				</div>
				<div class="dialog-body">
					<v-col class="dialog-body--error" align-self="center" v-if="error" cols="12" sm="6">
						<v-img width="100%" src="@/assets/images/error1.png"></v-img>
						<p class="text-center">
							Vous êtes nombreux à nous solliciter, nous mettons tout en oeuvre pour répondre à vos questions le plus
							vite !
						</p>
					</v-col>
					<div v-else class="chat-block">
						<div><img v-if="loading" src="@/assets/icons/assistant.svg" class="robot-icon" alt="robot" /></div>
						<div>
							<div
								class="bubble-chat"
								:class="{
									'bubble-chat--loading': loading,
								}"
							>
								<div v-if="loading" class="loading-wrapper">
									<div class="loading-dot-typing"></div>
								</div>
								<p v-if="loading" class="ortho-description pt-3">Est en train d'écrire...</p>
								<div id="typing-paragraph"></div>
							</div>
						</div>
					</div>
				</div>
			</v-card-text>
			<v-card-actions class="cancel-container">
				<v-spacer></v-spacer>
				<div class="cancel text-center" @click="close()">Fermer l'assistant</div>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import Axios from 'axios';
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';
import { renderTextTypingEffect } from '../../../../utils/typing-effect';
import { setOpacity, setDisplay, setZindexByClassNames } from '../../../../utils/helper';

export default {
	name: 'ExplanationDialog',
	props: {
		question: Object,
		explanationRules: {
			type: Function,
			required: true,
		},
	},
	data() {
		return {
			dialog: true,
			loading: true,
			error: false,
			intervalID: null,
			autoScrollEnabled: true,
			axiosSource: Axios.CancelToken.source(),
		};
	},
	computed: {
		...mapGetters('profile', { profileInfos: 'profileInfos' }),
		belowDesktop() {
			if (window.innerWidth <= 648) return true;
			return false;
		},
		questionLibelle() {
			const unicode = '\u2011';
			return this.question.libelle.replace(/-/g, unicode); // Prevent word break at hyphen
		},
	},
	mounted() {
		this.renderAnswerFromOpenAI();
		setDisplay('cc-157aw', 'none', 'important'); // Hide chat support
		document.body.style.top = `-${window.scrollY}px`;
		document.body.style.position = 'fixed';
		document.body.style.width = '100%';
	},
	destroyed() {
		setDisplay('cc-157aw', 'block'); // Hide chat support
		this.axiosSource.cancel();
		clearInterval(this.intervalID);
		const scrollY = document.body.style.top;
		document.body.style.position = '';
		document.body.style.top = '';
		window.scrollTo(0, parseInt(scrollY || '0') * -1);
	},
	methods: {
		...mapActions('exercice', ['getQuestionExplanationFromAI']),
		close() {
			this.explanationRules(this.question);
			this.dialog = false;
			setOpacity('hide-when-open-explanation', 1);
			setZindexByClassNames('hide-when-open-explanation', 1000);

			if (process.env.VUE_APP_MIXPANEL == 'production') {
				this.$mixpanel.track('exercice-closeExplanationSupport');
			}
		},
		async renderAnswerFromOpenAI() {
			setOpacity('hide-when-open-explanation', 0);
			setZindexByClassNames('hide-when-open-explanation', 0);
			try {
				const question = this.createQuestionForAI();
				const answer = await this.getQuestionExplanationFromAI({
					question,
					config: { cancelToken: this.axiosSource.token },
				});
				this.loading = false;
				this.intervalID = renderTextTypingEffect(answer, null, this.scrollToBottomDialogBody);
			} catch (e) {
				this.loading = false;
				this.error = true;
			}
		},
		scrollToBottomDialogBody() {
			const vCardTextElement = document.querySelector('.v-card__text');

			// Si l'auto-scroll est activé, scrolle automatiquement vers le bas
			if (this.autoScrollEnabled) {
				vCardTextElement.scrollTop = vCardTextElement.scrollHeight;
			}

			// Vérifie si le contenu dépasse la fenêtre visible
			const contentOverflows = vCardTextElement.scrollHeight > vCardTextElement.clientHeight;

			// Active la détection de scroll manuel uniquement si le contenu dépasse la fenêtre
			if (contentOverflows) {
				const stopAutoScroll = () => {
					this.autoScrollEnabled = false;
					vCardTextElement.removeEventListener('wheel', stopAutoScroll);
					vCardTextElement.removeEventListener('touchmove', stopAutoScroll);
				};

				vCardTextElement.addEventListener('wheel', stopAutoScroll, { once: true });
				vCardTextElement.addEventListener('touchmove', stopAutoScroll, { once: true });
			}
		},
		createQuestionForAI() {
			const userAnswer = this.question.answers[this.question.good_answer] === 'correct' ? 'incorrect' : 'correct';

			//get all answers in the future get only the wrong answers of the user
			let allAnswers = this.question.answers;

			//get the good answer
			const goodAnswer = this.question.answers[this.question.good_answer];

			//get userName only if user is logged
			var userName = '';
			if (Object.keys(this.profileInfos).length != 0)
				userName = "s'appelle " + this.profileInfos.first_name + ' et est de sexe ' + this.profileInfos.gender;

			try {
				const cleanCourseDescription = this.question.cours_id[0].description.replace(
					/(<\/?[^>]+(>|$))|(👉|🤟)|(&nbsp;)/g,
					''
				); // Remove html tag and emoji in cours description string TODO: improve this regrex to catch all emoji
				const tagName = this.question.cours_id[0].tags_id.name;
				const familyName = this.question.cours_id[0].tags_id.family_tag_id.name;
				const correctExample = this.question.examples_correct[0];
				const questionFormat = this.question.format_question;

				if (questionFormat == 3) {
					//if fill-in-the-blank format
					var promptQuestionFormat3 = `
					Tu es professeur de français et super pédagogue. On étudie la règle de ${familyName}, "${tagName}" avec la phrase à trou suivante : "${this.question.libelle}". Ton apprenant ${userName}, s'est trompé et pensait que la bonne réponse était : "${allAnswers}" alors que la seule bonne réponse est "${goodAnswer}". Tu sais que la bonne réponse est "${correctExample}" et que la règle étudiée est : "${cleanCourseDescription}".

					Dans un premier temps dit "salut" avec "👋🏼" à l'apprenant en lui disant " j'espère que tu vas bien" avec un émoji.
					Dans un second temps, rassure l'apprenant en utilisant un émoji encourageant, et dis-lui que vous allez analyser cela ensemble.

					Ensuite, explique en détail à ton apprenant pourquoi il s'est trompé. 
					La structuration se fera comme ceci : 
					1️⃣**Erreur identifiée** 🔍 : (en identifiant la mauvaise et la bonne réponse avec les émojis✅ et ❌ et met en gras l'endroit important).
					2️⃣**Règle** 📏 : (il faut que l'explication de la règle soit expliquée avec la question étudiée (pas généraliser)).
					3️⃣**Astuce mnémotechnique** 💡 : (très courte, simple et mémorable, liée directement à la règle).

					Ensuite ajoute un "Autre exemple 🔄 :" (donne un autre exemple en rapport avec la règle)
					- ✅Correct :
					- ❌Incorrect :

					Un message d'encouragement/compliment final avec le prénom de l'apprenant.

					Met en gras les parties importantes, n'utilise jamais l'italique, utilise des émojis à chaque ligne et, structure bien ta réponse en ajoutant des retours à la ligne et reste concis. Signe le message par "Martin d'OrthographIQ" avec un émoji poulpe.`;

					return promptQuestionFormat3;
				} else {
					//if incorrect or correct format
					var promptQuestionFormat2 = `

					Tu es professeur de français et super pédagogue.
					On étudie la règle de ${familyName}, "${tagName}". Ton apprenant s'appelle ${this.profileInfos.first_name} et est de sexe ${this.profileInfos.gender}. Les choix de réponses sont "correct" ou "incorrect" et la phrase est : "${this.question.libelle}". Tu sais que la phrase correcte est "${correctExample}". 

					La bonne réponse est :"${goodAnswer}".

					C'est simple, si la bonne réponse est "correct" alors l'apprenant a sélectionné incorrect, si la bonne réponse est "incorrect", alors l'apprenant a sélectionné correct.

					La règle étudiée est : "${cleanCourseDescription}."

					La structuration se fera exactement comme ceci : 
					" Dans un premier temps dit "salut" avec "👋🏼" à l'apprenant en lui disant " j'espère que tu vas bien" avec un émoji.
					Dans un second temps, rassure l'apprenant en utilisant un émoji encourageant, et dis-lui que vous allez analyser cela ensemble.

					1️⃣**Erreur identifiée** 🔍 : (rappelle l'erreur de l'apprenant (correct/incorrect), dit lui que la bonne réponse est "${goodAnswer}" et explique-lui son erreur en mettant du gras aux bons endroits ).
					2️⃣**Règle** 📏 : (il faut que l'explication de la règle soit expliquée avec la question étudiée (pas généralisée)).
					3️⃣**Astuce mnémotechnique** 💡 : (très courte, clair, simple et mémorable, liée directement à la règle).

					Ensuite ajoute un "Autre exemple 🔄 :" (donne un autre exemple en rapport avec la règle)
					- ✅Correct :
					- ❌Incorrect :

					Un message d'encouragement/compliment final avec le prénom de l'apprenant.

					Met en gras les parties importantes, utilise des émojis à chaque ligne et, structure bien ta réponse en ajoutant des retours à la ligne et reste concis. Signe le message par "Martin d'OrthographIQ" avec un émoji poulpe.`;

					return promptQuestionFormat2;
				}
			} catch (e) {
				return `J'essaye d'apprendre l'orthographe française, est-ce que la phrase "${this.question.libelle}" est ${userAnswer} ? On étudie l'orthographe et la grammaire de la phrase. Peux-tu m'expliquer pourquoi en 250 caractères.`;
			}
		},
	},
};
</script>

<style scoped>
.dialog-card-container {
	padding-left: 2rem;
	padding-right: 2rem;
}

.question-libelle {
	word-break: keep-all;
}

.dialog-card p {
	font-size: 1.1rem;
	line-height: 1.5;
}
.question-block {
	display: flex;
	gap: 8px;
	align-items: center;
	flex-wrap: wrap;
	margin-bottom: 40px;
	margin-left: 22px;
	margin-right: 22px;
}

.question-block > span {
	color: black;
	font-family: 'Lato';
	font-size: 25px;
	font-weight: bold;
	line-height: 2rem;
}

.dialog-body {
	padding: 16px 22px;
	display: flex;
	gap: 1rem;
	height: 60vh;
}

.chat-block {
	display: flex;
}

.bubble-chat {
	/* background-color: #f2fbf9; */
	min-width: 120px;
	/* padding: 15px; */
	/* border-radius: 8px; */
	flex-basis: 100%;
}

.bubble-chat--loading {
	background: none;
	padding: 0;
}

.loading-wrapper {
	/* background-color: #f2fbf9; */
	height: 42px;
	border-radius: 8px;
	padding: 15px;
}

.actions {
	justify-content: flex-end;
}
.robot-icon {
	width: 40px;
	height: 40px;
	display: block;
	margin-right: 8px;
}
.close-btn {
	width: auto;
	height: auto;
}
.close-btn:hover::before {
	opacity: 0;
}
.cancel-container {
	margin-right: 28px;
	margin-left: 28px;
	margin-bottom: 14px;
}
.cancel {
	background-color: white;
	border-radius: 5px;
	border: #000000 1px solid;
	cursor: pointer;
	padding: 5px 30px;
	font-family: 'Lato';
	font-size: 20px;
}
.ortho-questions-answers-cour {
	padding: 2px 15px;
}

.dialog-body--error {
	margin: 0 auto;
}

@media screen and (max-width: 968px) {
	.dialog-body {
		padding: 16px 0;
	}
	.question-block {
		margin-left: 0px;
		margin-right: 0px;
	}
	.cancel-container {
		margin-right: 0px;
		margin-left: 0px;
		margin-bottom: 10px;
	}
}

@media screen and (max-width: 648px) {
	.dialog-body {
		padding: 0;
		height: auto;
	}
	.actions {
		justify-content: center;
	}
	.robot-icon {
		width: 30px;
		height: 30px;
		margin-right: 8px;
	}
	.cancel {
		width: 100%;
		margin-right: 0;
	}
}
@media screen and (max-width: 468px) {
	.robot-icon {
		width: 25px;
		height: 25px;
	}
}
</style>

<template>
	<div class="formatAGlobalContainer">
		<zoom-center-transition group>
			<v-row
				class="no-row-css"
				v-for="(questionA, index) in questions.slice(countFormat1 - 1, countFormat1)"
				:key="index"
			>
				<v-row :id="'full-formatAContainer' + questionA._id">
					<v-col md="4" offset-md="1" sm="12" cols="12">
						<div class="formatAContainerQuestion" :id="'formatAContainerQuestion' + questionA._id">
							<div class="ortho-align-center">
								<div class="ortho-description mb30">
									<v-btn class="indicator-section" depressed>
										<span>Entrainements</span>
									</v-btn>
								</div>
								<div class="ortho-description pt20 mb10">
									<span>{{ questionA.action }}</span>
								</div>
								<div class="ortho-questions ortho-bold libelle formatA-question mb20" :id="'libelle' + questionA._id">
									<span>{{ questionA.libelle }}</span>
								</div>
							</div>
							<v-col md="12" sm="12" cols="12" v-for="(answer, indexAnswer) in questionA.answers" v-bind:key="answer">
								<div class="ortho-align-center">
									<div
										class="hvr-grow ortho-questions-answers ortho-questions-answers-null"
										v-bind:ref="questionA._id"
										v-bind:data-id="questionA._id"
										v-bind:data-answer="indexAnswer"
										:class="{
											'ortho-questions-answers-correct-full':
												userAnswer != null && indexAnswer == questionA.good_answer,
											'ortho-questions-answers-correct':
												userAnswer == indexAnswer && indexAnswer == questionA.good_answer,
											'ortho-questions-answers-incorrect-border':
												userAnswer == indexAnswer && indexAnswer != questionA.good_answer,
											'ortho-questions-answers-incorrect': userAnswer != null && indexAnswer != questionA.good_answer,
											'ortho-disable': isDisabled,
										}"
										@click="answerQuestionFormatA(questionA, indexAnswer)"
									>
										{{ answer }}
									</div>
								</div>
							</v-col>
						</div>
					</v-col>
					<v-col
						md="4"
						offset-md="1"
						sm="12"
						cols="12"
						class="formatACoursContainer"
						:class="{
							pb20: $vuetify.breakpoint.smAndDown,
							pt20: $vuetify.breakpoint.mdAndUp,
						}"
					>
						<Panels
							:id="'panel-' + questionA._id"
							:question="questionA._id"
							:message="bravo"
							:responded="responded"
							:allQuestions="questions"
							:count="countFormat1"
							class="formatAContainerQuestion"
							@ChangeTrainingQuestion="onChangeTrainingQuestion()"
							@goToQuestions="LeavingTraining()"
						/>
					</v-col>
				</v-row>
			</v-row>
		</zoom-center-transition>
	</div>
</template>

<script>
import Panels from '../../panels/default/Training';
import VueScrollTo from 'vue-scrollto';
import soundCorrect from '@/assets/sound/goodAnswer.mp3';
import soundError from '@/assets/sound/wrongAnswer.mp3';

let answersGiven = [];
let goodAnswersGiven = [];
let wrongAnswersGiven = [];

export default {
	name: 'TrainingQuestions',
	props: {
		questions: Array,
		countStory: Number,
	},
	components: {
		Panels,
	},
	data() {
		return {
			storageKeySound: 'acceptSound',
			countFormat1: 1,
			bravo: '',
			responded: false,
			userAnswer: null,
			isDisabled: false,
		};
	},
	mounted() {
		window.scrollTo(0, 0);
	},
	methods: {
		answerQuestionFormatA(question, answer) {
			const cookie = window.localStorage.getItem(this.storageKeySound);
			var audioCorrect = new Audio(soundCorrect);
			var audioWrong = new Audio(soundError);
			this.userAnswer = answer;
			this.isDisabled = true;
			let answerClicked = event.currentTarget.dataset.answer;
			this.responded = true;
			if (answerClicked == question.good_answer) {
				if (cookie == null) {
					audioCorrect.play();
				}
				goodAnswersGiven.push(question.answers[answerClicked]);
				answersGiven.push(question.answers[answerClicked]);
				this.bravo = '👍 Top, tu maîtrises !';
			} else {
				if (cookie == null) {
					audioWrong.play();
				}
				wrongAnswersGiven.push(question.answers[answerClicked]);
				answersGiven.push(question.answers[answerClicked]);
				this.bravo = '😯 Pas facile celle-là...';
			}
			//scroll to the next question, 1s delay between clic and scroll
			setTimeout(function () {
				VueScrollTo.scrollTo('#panel-' + question._id, {
					offset: (VueScrollTo.offset = -80),
				});
			}, 500);
		},
		onChangeTrainingQuestion() {
			this.userAnswer = null;
			this.isDisabled = false;
			this.responded = false;
			this.bravo = '';
			this.countFormat1++;
		},
		LeavingTraining() {
			this.$emit('startQuestions');
		},
	},
};
</script>

<style scoped>
.formatAContainerQuestion {
	margin-top: 50px;
}
@media only screen and (max-width: 600px) {
	.no-row-css {
		margin: 0 !important;
	}
}
</style>

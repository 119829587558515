<template>
	<v-col
		offset-md="1"
		md="5"
		sm="12"
		cols="12"
		:class="{
			'pb20 mt20 mb20': $vuetify.breakpoint.smAndDown,
			pt20: $vuetify.breakpoint.mdAndUp,
		}"
	>
		<div :id="'expansionPanelsDiv' + question._id" class="ortho-display-none panels-questions">
			<div
				class="ortho-questions ortho-bold mb10"
				:class="{
					'ortho-display-none': $vuetify.breakpoint.mdAndUp,
				}"
			>
				<div class="assistant-container">
					<img src="@/assets/icons/assistant.svg" class="assistant-icon" alt="" />
					<span class="ortho-text-blue">Assistant</span>
				</div>
			</div>
			<v-expansion-panels
				v-model="panel"
				multiple
				class="shadow-blue-not-openned"
				:id="'expansionPanels' + question._id"
			>
				<v-expansion-panel>
					<v-expansion-panel-header @click="onClickExpansionPanels(question)" class="ortho-expansion-panel-header">
						<div class="ortho-questions-cours-title">
							<v-row class="align-center">
								<v-col md="3" sm="4" cols="12">
									<div class="ortho-questions-answers-cour ortho-questions-answers-correct ml-0">
										{{ question.answers[question.good_answer] }}
									</div>
								</v-col>
								<v-col md="9" sm="8" cols="12">
									<span :id="'subdescription-course' + question._id" class="subdescription">{{
										question.cours_id[0].sub_description
									}}</span>
									<span
										:id="'see-rules-again' + question._id"
										class="ortho-text-green ortho-display-none revoir-action-sentence"
										>L'astuce</span
									>
								</v-col>
							</v-row>
						</div>
					</v-expansion-panel-header>
					<v-expansion-panel-content>
						<div class="ortho-questions-cours-description">
							<v-row>
								<v-col md="12" sm="12" cols="12">
									<span v-html="question.cours_id[0].description"></span>
								</v-col>
							</v-row>
						</div>
						<div>
							<v-row v-for="correct in question.examples_correct" :key="correct">
								<div class="ortho-question-cours-example">
									<div class="ortho-question-cours-item">
										<div>
											{{ correct }}
										</div>
									</div>
								</div>
							</v-row>
						</div>
						<div class="ortho-button-exercice-container mt20 flex-column flex-md-row" v-if="index > countFormat2 - 2">
							<div
								class="mb-2 mb-md-0 ortho-questions-cours-button hvr-sweep-to-right"
								@click="goToNextquestion(question)"
							>
								J'ai compris !
							</div>
							<div class="ortho-questions-cours-not-understand hvr-sweep-to-right" @click="openExplanationSupport()">
								Je ne comprends pas
							</div>
							<ExplanationDialog
								:question="question"
								:explanationRules="explanationRules"
								v-if="openExplantion"
							></ExplanationDialog>
						</div>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>
		</div>
		<v-snackbar v-model="snackbar" :top="true" :right="true" color="#2dc7ae" :timeout="3000">
			<div class="d-flex justify-space-between align-center">
				<span> Merci pour le retour !</span>
				<v-btn dark text @click="snackbar = false">Fermer</v-btn>
			</div>
		</v-snackbar>
	</v-col>
</template>

<script>
import { mapGetters } from 'vuex';
import ExplanationDialog from './ExplanationDialog';
export default {
	name: 'Questions',
	components: {
		ExplanationDialog,
	},
	props: {
		question: Object,
		index: Number,
		countFormat2: Number,
	},
	data() {
		return {
			panel: [0],
			snackbar: false,
			openExplantion: false,
		};
	},
	computed: {
		...mapGetters('exercice', {
			chatGptExplanation: 'chatGptExplanation',
		}),
	},
	methods: {
		goToNextquestion(question) {
			this.closePanels(question);
			this.$emit('nextQuestion');
		},
		enoughInfoToFormQuestionForAI() {
			try {
				const tagName = this.question.cours_id[0].tags_id.name;
				const familyName = this.question.cours_id[0].tags_id.family_tag_id.name;
				const correctExample = this.question.examples_correct[0];
				if (tagName && familyName && correctExample) return true;
				return false;
			} catch (error) {
				return false;
			}
		},
		openExplanationSupport() {
			const enoughInfo = this.enoughInfoToFormQuestionForAI();
			if (this.chatGptExplanation === 'ON' && enoughInfo) {
				this.openExplantion = true;
				if (process.env.VUE_APP_MIXPANEL == 'production') {
					this.$mixpanel.track('exercice-openExplanationSupport');
				}
			} else {
				this.explanationRules(this.question);
			}
		},
		async saveFeedback(question) {
			const resFeedbackCours = await this.$store.dispatch('exercice/rulesUnderstanding', {
				coursId: question.cours_id[0]._id,
				questionId: question._id,
			});
			if (resFeedbackCours.status == 200) {
				this.snackbar = true;
			}
		},
		explanationRules(question) {
			this.openExplantion = false;
			this.closePanels(question);
			this.$emit('nextQuestion');
			const enoughInfo = this.enoughInfoToFormQuestionForAI();
			if (this.chatGptExplanation === 'OFF' || enoughInfo == false) {
				this.saveFeedback(question);
			}
		},
		closePanels(question) {
			let libelle = document.getElementById('libelle' + question._id);
			libelle.classList.remove('ortho-bold');

			let container = document.getElementById('question-answer-container' + question._id);
			container.classList.remove('border-alignement');

			let subdescription = document.getElementById('subdescription-course' + question._id);
			subdescription.classList.add('ortho-display-none');

			let seeRulesAgain = document.getElementById('see-rules-again' + question._id);
			seeRulesAgain.classList.remove('ortho-display-none');

			//high shadow and low shadow
			let expansionPanelShadow = document.getElementById('expansionPanels' + question._id);
			expansionPanelShadow.classList.add('shadow-blue-not-openned');
			expansionPanelShadow.classList.remove('shadow-blue');

			this.panel = [null];
		},
		onClickExpansionPanels(question) {
			let libelle = document.getElementById('libelle' + question._id);

			let expansionPanelShadow = document.getElementById('expansionPanels' + question._id);

			let subdescription = document.getElementById('subdescription-course' + question._id);
			subdescription.classList.remove('ortho-display-none');

			// let seeRulesAgain = document.getElementById('see-rules-again' + question._id);
			// seeRulesAgain.classList.add('ortho-display-none');

			if (libelle) {
				let classNames = libelle.className;
				if (classNames && classNames.includes('ortho-bold')) {
					libelle.classList.remove('ortho-bold');
				} else {
					libelle.classList.add('ortho-bold');
				}
			}

			//high shadow and low shadow on click
			if (expansionPanelShadow) {
				let classNames = expansionPanelShadow.className;
				if (classNames && classNames.includes('shadow-blue-not-openned')) {
					expansionPanelShadow.classList.remove('shadow-blue-not-openned');
					expansionPanelShadow.classList.add('shadow-blue');
				} else {
					expansionPanelShadow.classList.remove('shadow-blue');
					expansionPanelShadow.classList.add('shadow-blue-not-openned');
				}
			}
		},
	},
};
</script>
<style scoped>
.assistant-container {
	/* always write it like this for safari */
	display: flex;
	align-items: center;
	justify-content: left;
}

.revoir-action-sentence {
	margin-left: 10px;
}

@media screen and (max-width: 600px) {
	.assistant-icon {
		width: 9%;
		margin-right: 10px;
	}
	.revoir-action-sentence {
		margin-left: 0px;
	}
}
@media screen and (max-width: 959px) {
	.ortho-questions-cours-button {
		width: 100%;
	}
}
</style>

<template>
	<div>
		<div
			v-if="!responded"
			:id="'imageFormatA' + question"
			class="imageFormatA-container"
			title="Réponds à la question pour voir l'astuce"
		>
			<img src="@/assets/images/coverTeasing.png" class="ortho-image-format-a" />
		</div>
		<div v-else :id="'expansionPanelsDiv' + question" class="shadow-blue paddingPanel">
			<div>
				<div class="ortho-questions-cours-title">
					<v-row class="font-weight-bold text-center">
						<v-col md="12" sm="12" cols="12" class="text-center">
							{{ message }}
						</v-col>
					</v-row>
				</div>
				<div v-for="(cour, index) in questionDetails.cours_id" :key="'description-' + index">
					<div class="ortho-questions-cours-description">
						<v-row>
							<v-col md="12" sm="12" cols="12">
								<span v-html="cour.description"></span>
							</v-col>
						</v-row>
					</div>
				</div>
				<div class="mt20 pt20">
					<div
						class="ortho-entrainement-button hvr-shrink"
						v-if="count < allQuestions.length"
						@click="goToNextquestionA()"
					>
						Refaire un entrainement
					</div>
					<div class="ortho-next-exerices-start-button hvr-sweep-to-right" @click="next()">
						Démarrer le Quiz
						<v-icon class="ortho-icon-black fs15 ml5">fas fa-arrow-right</v-icon>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
	name: 'Training',
	props: {
		question: String,
		message: String,
		responded: Boolean,
		allQuestions: Array,
		count: Number,
	},
	data() {
		return {
			panel: [0],
		};
	},
	computed: {
		...mapGetters('exercice', {
			questionDetails: 'questionDetails',
		}),
	},
	created() {
		this.getCoursQuestions(this.question);
	},
	methods: {
		...mapActions('exercice', ['getCoursQuestions']),
		goToNextquestionA() {
			this.$emit('ChangeTrainingQuestion');

			this.panel = [null];

			//for mobile : always display top of the page
			window.scrollTo(0, 0);
		},
		next() {
			this.$emit('goToQuestions');
		},
	},
};
</script>
<style lang="scss" scoped>
.paddingPanel {
	padding: 24px 16px;
	border-radius: 5px;
}
</style>
